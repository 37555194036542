module.exports = [{
      plugin: require('/home/bo-yakitarako/techtech/koyama-shoji/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Cabin","Open Sans"]}},
    },{
      plugin: require('/home/bo-yakitarako/techtech/koyama-shoji/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/bo-yakitarako/techtech/koyama-shoji/node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{}},
    },{
      plugin: require('/home/bo-yakitarako/techtech/koyama-shoji/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149464615-1"},
    }]
